import Pokemon from './Pokemon';
import { useState, useEffect } from 'react';

function Pokemons(props) {
  const [loaded, setLoaded] = useState(false);
  const [pokemons, setPokemons] = useState({});
  const [error, setError] = useState(null);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    fetch(`https://pokeapi.co/api/v2/pokemon?offset=${offset}&limit=${props.limit}`)
      .then((res) => res.json())
      .then(
        (result) => {
          setPokemons(result);
          setLoaded(true);
        },
        (error) => {
          setError(error);
          setLoaded(true);
        },
      );
  }, [props.limit, offset]);

  function paginate() {
    return (
      <div className="paginate">
        {pokemons.previous && (
          <a
            href="#0"
            onClick={(e) => {
              e.preventDefault();
              setPokemons({});
              let newOffset = offset - parseInt(props.limit);
              setOffset(newOffset < 0 ? 0 : newOffset);
            }}
            className="clickable"
          >
            Back
          </a>
        )}
        {pokemons.next && (
          <a
            href="#0"
            onClick={(e) => {
              e.preventDefault();
              setPokemons({});
              let newOffset = offset + parseInt(props.limit);
              setOffset(newOffset < 0 ? 0 : newOffset);
            }}
            className="clickable"
          >
            Next
          </a>
        )}
      </div>
    );
  }

  if (loaded && pokemons.results) {
    if (pokemons.results.length > 0) {
      return (
        <div>
          {paginate()}
          <div className="pokemons">
            {pokemons.results.map((pokemon, i) => {
              return <Pokemon key={i} url={pokemon.url} />;
            })}
          </div>
          {paginate()}
        </div>
      );
    }
    if (error) {
      return <b>{error}</b>;
    }
  } else {
    return <b>Pokemons Loading</b>;
  }
}

export default Pokemons;
