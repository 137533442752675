import Pokemons from './Pokemons';
import Header from './components/Header';
import './App.css';
import { useState } from 'react';

function App() {
  const [limit, setLimit] = useState(20);

  return (
    <div className="App">
      <Header />
      <label htmlFor="limit">Limit : </label>
      <input
        type="number"
        id="limit"
        value={limit}
        onChange={(e) => {
          setLimit(e.target.value);
        }}
        step="5"
      />
      <Pokemons limit={limit} />
    </div>
  );
}

export default App;
