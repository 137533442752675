import React, { useState, useEffect } from 'react';

function Pokemon(props) {
  const [pokemon, setPokemon] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [front, setFront] = useState(true);

  useEffect(() => {
    fetch(props.url)
      .then((res) => res.json())
      .then(
        (result) => {
          setPokemon(result);
          setLoaded(true);
        },
        (error) => {
          setError(error);
          setLoaded(true);
        },
      );
  }, [props.url]);

  function flipImage(e) {
    e.preventDefault();
    setFront(!front);
  }

  return (
    <div className={`pokemon ${loaded && pokemon && pokemon.types[0].type.name}`}>
      {loaded && pokemon ? (
        <div>
          <h3>
            {pokemon.id}.{pokemon.name.charAt(0).toUpperCase() + pokemon.name.slice(1)}
          </h3>
          <p>
            <b>Weight : </b> {pokemon.weight}
          </p>
          <p>
            <b>Type(s) : </b>{' '}
            {pokemon.types
              .map((type) => {
                return type.type.name;
              })
              .join('/')}
          </p>
          <div className="pokemon_image">
            <p>
              <a href="#0" onClick={flipImage}>
                Rotate
              </a>
            </p>
            {front ? (
              <img src={pokemon.sprites.front_default} width="200" height="200" alt="front" />
            ) : (
              <img src={pokemon.sprites.back_default} width="200" height="200" alt="back" />
            )}
          </div>
        </div>
      ) : (
        <p>Loading....</p>
      )}
      {error && error}
    </div>
  );
}

export default Pokemon;
